import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image2'

const StyledUspWithTextAndPerson = styled.section``

const UspWithTextAndPersonImage = styled(Image)`
  position: absolute !important;

  & img {
    object-fit: contain !important;
  }

  @media (min-width: 992px) {
    left: -150px;
    top: 50%;
    height: 300px;
    width: 300px;
    margin-top: -150px;
  }

  @media (max-width: 991px) {
    left: -125px;
    top: -50px;
    height: 250px;
    width: 250px;
  }

  @media (max-width: 767px) {
    left: -100px;
    top: -25px;
    height: 200px;
    width: 200px;
  }
`

const UspWithTextAndPersonUsp = styled.div`
  color: ${(props) => props.theme.color.text.purple};
  background-color: ${(props) => props.theme.color.face.background};
  font-weight: ${(props) => props.theme.font.weight.xm};
  border-radius: 25px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0 !important;
  }

  & svg {
    height: 20px;
    width: 20px;
    margin-right: 0.75rem;

    & path {
      fill: #0098a5;
    }
  }

  @media (min-width: 992px) {
    padding-left: 150px;
  }

  @media (max-width: 991px) {
    padding-left: 75px;
  }

  @media (max-width: 767px) {
    padding-left: 50px;
  }

  @media (max-width: 575px) {
    padding-left: 25px;
    position: relative;

    &:before {
      content: '';
      background-color: ${(props) => props.theme.color.face.background};
      position: absolute;
      right: 100%;
      width: 500px;
      top: 0;
      bottom: 0;
    }
  }
`

const UspWithTextAndPersonDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text.purple};

  & a {
    text-decoration: underline;
  }

  & .button a {
    text-decoration: none;
  }
`

interface UspWithTextAndPersonProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_UspWithTextAndPerson
}

const UspWithTextAndPerson: React.FC<UspWithTextAndPersonProps> = ({
  fields,
}) => (
  <StyledUspWithTextAndPerson className="my-1 my-lg-5">
    <div className="container py-5">
      <div className="row justify-content-between">
        <div
          className={`${
            fields.view === 'right'
              ? 'order-3 col-sm-5'
              : 'order-1 col-sm-6 pr-md-5'
          }`}
        >
          <div className="position-relative">
            <UspWithTextAndPersonImage
              className="d-sm-block d-none"
              image={fields.imagePerson}
            />
            <div>
              {fields?.usp?.map((edge, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <UspWithTextAndPersonUsp className="mb-4 py-3 pr-3" key={index}>
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 174.239 174.239"
                  >
                    <path
                      d="M74.439,157.519c-1.59,1.848-4.34,1.989-6.111,0.313L1.38,94.468c-1.771-1.676-1.848-4.496-0.173-6.267l21.33-22.539
    c1.676-1.771,4.496-1.848,6.267-0.173l36.571,34.611c1.771,1.676,4.521,1.535,6.111-0.313l71.447-83.015
    c1.59-1.848,4.404-2.059,6.251-0.468l23.518,20.242c1.848,1.59,2.059,4.404,0.468,6.252L74.439,157.519z"
                    />
                  </svg>
                  {edge?.title}
                </UspWithTextAndPersonUsp>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`${
            fields.view === 'right' ? 'col-sm-5' : 'col-sm-6'
          } order-2 mt-4 mt-sm-0`}
        >
          <UspWithTextAndPersonDescription content={fields?.description} />
        </div>
      </div>
    </div>
  </StyledUspWithTextAndPerson>
)

export default UspWithTextAndPerson
